import {Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import {toast } from 'react-toastify';
import { useAuth } from "react-oidc-context";
import { solveMessage } from "../utils";

const AddUser = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [biCni, setBiCni] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const toastStyle = {fontSize: '13.3px'};
  
  
  const saveUser = async (e) => {
    e.preventDefault();
    setLoad(true)
    try {
      const response = await axios.post(
        `${url}/users/create`,
        {
          firstName: firstName,
          lastName: lastName,
          bi: biCni,
          birthDate: birthDate,
          email: email,
          phone: phone
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false)
      toast.success('Adicionado com Sucesso', {style: toastStyle});
      navigate("/listUser");
    } catch (error) {
      setLoad(false)
      alert(solveMessage(error))
      toast.error(solveMessage(error), {style: toastStyle});
    }
  };

  return (
    <div className="container-sm pt-4 text-black ">
      <div className="mx-auto w-75">
        <p className="small" style={{marginLeft: '105px'}}>
          <Link to="/" className="mutec text-decoration-none ic">Home »{" "}</Link>
          <Link to="/listUser" className="mutec text-decoration-none ic">Utilizador »{" "}</Link>
        </p>
          <h5 className=" mb-1 floa-start ic" style={{ fontFamily: "Roboto", marginLeft: '105px'}}>
            Criar Utilizador
          </h5>
      
        <form className=" smollText p-3 mx-auto w-75 shadow-lg bg-white" onSubmit={saveUser}>
          <div className="row">
            <div className="col-md">
              <label for="firstName" className="form-label labeFormlStyle">
                Primeiro Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="firstName"
                type="text"
                placeholder=""
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="col-md" >
              <label for="lastName" className="form-label labeFormlStyle">
                Ultimo Nome
              </label>
              <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
              </span>
              <input
                className="form-control form-control-sm"
                id="lastName"
                type="text"
                placeholder=""
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
          <div className="col-md">
              <label for="bi" className="form-label labeFormlStyle">
                BI/CNI
              </label>
              <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
              </span>
              <input
                className="form-control form-control-sm"
                id="bi"
                type="text"
                placeholder=""
                value={biCni}
                onChange={(e) => setBiCni(e.target.value)}
              />
            </div>

            <div className="col-md">
              <label for="birthDate" className="form-label labeFormlStyle">
                Data de Nascimento
              </label>
              <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
              </span>
              <input
                className="form-control form-control-sm"
                id="birthDate"
                type="datetime-local"
                placeholder=""
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label for="email" className="form-label labeFormlStyle">
                 Email
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="email"
                type="test"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="col-md">
              <label for="phone" className="form-label labeFormlStyle">
                  Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="phone"
                type="test"
                placeholder=""
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light sidItemL2"
            >
               {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listUser"
              type="button"
              className="btn btn-sm px-3 float-end me-1 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
    </div>
    </div>
  );
};

export default AddUser;
