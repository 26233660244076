import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { solveMessage } from "../utils";

const ListUser = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [loadPage, setLoadPage] = useState(false);

  const getUsers = useCallback(async () => {
    setLoadPage(true)
    try {
      const response = await axios.get(`${url}/users`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setLoadPage(false)
      setUsers(response.data);
    } catch (error) {
      setLoadPage(false)
      console.log(solveMessage(error));
    }
  }, [setUsers]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const allowUser = async (idUser) => {
    try {
      await axios.put(`${url}/users/${idUser}/allow`, null, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      toast.success("User Ativado", {
        style: { fontSize: "13.3px" },
      });
      getUsers();
    } catch (error) {
      toast.error(solveMessage(error), {
        style: { fontSize: "13.3px", },
      });
    }
  };

  const disallowUser = async (idUser) => {
    try {
      await axios.delete(`${url}/users/${idUser}/disallow`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      toast.success("Aprovação anulada", {
        style: { fontSize: "13.3px" },
      });
      getUsers();
    } catch (error) {
      toast.error(solveMessage(error), {
        style: { fontSize: "13.3px" },
      });
    }
  };

  const deleteUser = async (idUser) => {
    try {
      await axios.delete(`${url}/users/${idUser}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      getUsers();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-sm pt-4">
      <h5 className="mb-3 ic" style={{ fontFamily: "Roboto" }}>
        Listagem de Utilizador
      </h5>

      <div className="clearfix mb-2">
        <Link
          to={"/addUser"}
          className="btn btn-sm sidItemL2 float-end text-light "
        >
          <i className="fas fa-plus-circle pe-1"></i>Novo
        </Link>

        <input
          type="search"
          //onChange={filter}
          className="form-control float-start w-25 fas fa-search bg-white"
          placeholder="&#xf002;"
        />
      </div>

      <div class="table-responsive-sm ">
        <table className=" table table-sm shadow bg-white align-middle p-3 ">
          {/*loading && <caption className="spinner-border ic2"></caption>*/}
          <thead className="mediunText ">
            <tr>
              <th className="ps-3">Nº</th>
              <th className="ps-3">Primeiro Nome</th>
              <th className="ps-3">Ultimo Nome</th>
              <th className="ps-3">Aprovado</th>
              <th className="ps-3">email</th>
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody className="smollText border border-top-0">
            {users.length > 0 ? (
              users.map((usr, index) => (
                <tr key={usr.id}>
                  <td className="ps-3">{index + 1}</td>
                  <td className="ps-3">{usr.firstName}</td>
                  <td className="ps-3">{usr.lastName}</td>
                  <td className="ps-3">{usr.approved ? "Sim" : "Não"}</td>
                  <td className="ps-3">{usr.email}</td>
                  <td className="text-center">
                    <Link
                      to={`/viewUser/${usr.id}`}
                      className="btn fas fa-eye p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Vizualizar"
                    ></Link>
                    <Link
                      to={`/editUser/${usr.id}`}
                      className="btn fa fa-edit p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Editar"
                    ></Link>
                    <button
                      onClick={() => {
                        const confirmBox = window.confirm(
                          "Tem a certeza que quer aprovar este utilizador?"
                        );
                        if (confirmBox === true) {
                          allowUser(usr.id);
                        }
                      }}
                      className="btn fas fa-user-check p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Aprovar"
                    ></button>
                    <button
                      onClick={() => {
                        const confirmBox = window.confirm(
                          "Tem a certeza que quer anular aprovação dete utilizador?"
                        );
                        if (confirmBox === true) {
                          disallowUser(usr.id);
                        }
                      }}
                      className="btn fas fa-user-slash p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Anular aprovação"
                    ></button>
                    <button
                      onClick={() => {
                        const confirmBox = window.confirm(
                          "Confirma Deleção deste Utilizador?"
                        );
                        if (confirmBox === true) {
                          deleteUser(usr.id);
                        }
                      }}
                      className="btn fas fa-trash-alt p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Deletar"
                    ></button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>
                  <h4 className="text-center pt-5 ic2">Sem Resultado!</h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListUser;
