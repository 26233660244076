import { Link } from "react-router-dom";
import { Pagination } from "./Pagination";
import { useState } from "react";

const List = ({
  title = "Items",
  columnTitles = [],
  items = [],
  deleteItem = () => {},
  filter = () => {},
  originLink = "",
  viewBaseLink = "",
  editBaseLink = "",
  newLink = "",
  loading = true,
  showEditButton = true,
}) => {
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [itensPorPagina] = useState(9);

  const indexDoUltimoItem = paginaAtual * itensPorPagina;
  const indexDoPrimeiroItem = indexDoUltimoItem - itensPorPagina;

  const itensAtuais = items.slice(indexDoPrimeiroItem, indexDoUltimoItem);

  const paginate = (numeroDaPagina) => setPaginaAtual(numeroDaPagina);

  return (
    <div className="container-sm pt-4">
      <h5 className="mb-3 ic" style={{ fontFamily: "Roboto" }}>
        Listagem de {title}
      </h5>

      <div className="clearfix mb-2">
        <Link
          to={newLink}
          className="btn btn-sm sidItemL2 float-end text-light "
        >
          <i className="fas fa-plus-circle pe-1"></i>Novo
        </Link>

        <input
          type="search"
          onChange={filter}
          className="form-control float-start w-25 fas fa-search bg-white"
          placeholder="&#xf002;"
        />
      </div>

      <div class="table-responsive-sm ">
        <table className=" table table-sm shadow bg-white align-middle p-3 ">
          {loading && <caption className="spinner-border ic2"></caption>}
          <thead className="mediunText ">
            <tr>
              <th className="ps-3">Nº</th>
              {columnTitles.map((column) => (
                <th key={column.key} className="">
                  {column.value}
                </th>
              ))}
              <th className="text-center">Ações</th>
            </tr>
          </thead>
          <tbody className="smollText border border-top-0">
            {items.length > 0 ? (
              itensAtuais.map((item, index) => (
                <tr key={item.id} className="">
                  <td className="ps-3">{index + 1}</td>
                  {columnTitles.map((column) => (
                    <td key={item[column.key]} className="ps-2">
                      {item[column.key]}
                    </td>
                  ))}
                  <td className="text-center">
                    <Link
                      to={`${viewBaseLink}/${item.id}`}
                      className="btn fas fa-eye p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Vizualizar"
                    ></Link>
                    {item.contratType !== "Externo" && (
                      <Link
                        to={`${editBaseLink}/${item.id}`}
                        className="btn fa fa-edit p-2 ic2 smollText"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        title="Editar"
                      ></Link>
                    )}
                    <button
                      onClick={() =>
                        window.confirm("Confirmar Deleção")
                          ? deleteItem(item.id)
                          : ""
                      }
                      className="btn fas fa-trash-alt p-2 ic2 smollText"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Deletar"
                    ></button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columnTitles.length + 2}>
                  <h4 className="text-center pt-5 ic2">Sem Resultado!</h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Pagination
          paginaAtual={paginaAtual}
          itensPorPagina={itensPorPagina}
          itensTotais={items.length}
          originLink={originLink}
          paginate={paginate}
        />
      </div>
    </div>
  );
};

export default List;
