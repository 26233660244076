import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { dateTimeFormat, solveMessage } from "../utils";

const EditUser = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { idUser } = useParams();
  const { user } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [biCni, setBiCni] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const toastStyle = {fontSize: '13.3px'};

  const getUser = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/users`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      const us = await response.data.filter((insp) => insp.id === idUser);
      setFirstName(us[0].firstName);
      setLastName(us[0].lastName);
      //setBiCni(usr[0].bi);
      setEmail(us[0].email);
      //setPhone(usr[0].phone);
      //setBirthDate(usr[0].birthDate);
    } catch (error) {
      //console.log(solveMessage(error));
    }
  }, [setFirstName, setLastName, setEmail]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const updateUser = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const response = await axios.put(
        `${url}/users/${idUser}/allow`,
        {
          firstName: firstName,
          lastName: lastName,
          bi: biCni,
          birthDate: birthDate,
          email: email,
          phone: phone,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false);
      toast.success("Actualizado", {style: toastStyle} );
      navigate("/listUser");
    } catch (error) {
      setLoad(false);
      toast.error(solveMessage(error), {style: toastStyle});
    }
  };
   
  return (
    <div className="container-sm pt-4 text-black ">
      <div className="mx-auto w-75">
        <h5
          className=" mb-1 floa-start ic"
          style={{ fontFamily: "Roboto", marginLeft: "105px" }}
        >
          Editar Utilizador
        </h5>

        <form
          className=" smollText p-3 mx-auto w-75 shadow-lg bg-white"
          onSubmit={updateUser}
        >
          <div className="row">
            <div className="col-md">
              <label for="firstName" className="form-label labeFormlStyle">
                Primeiro Nome
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="firstName"
                type="text"
                placeholder=""
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="col-md">
              <label for="lastName" className="form-label labeFormlStyle">
                Ultimo Nome
              </label>
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
              <input
                className="form-control form-control-sm"
                id="lastName"
                type="text"
                placeholder=""
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label for="bi" className="form-label labeFormlStyle">
                BI/CNI
              </label>
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
              <input
                className="form-control form-control-sm"
                id="bi"
                type="text"
                placeholder=""
                value={biCni}
                onChange={(e) => setBiCni(e.target.value)}
              />
            </div>

            <div className="col-md">
              <label for="birthDate" className="form-label labeFormlStyle">
                Data de Nascimento
              </label>
              <span className="text-danger" style={{ fontSize: "22px" }}>
                *
              </span>
              <input
                className="form-control form-control-sm"
                id="birthDate"
                type="datetime-local"
                placeholder=""
                value={dateTimeFormat(birthDate)}
                onChange={(e) => setBirthDate(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <label for="email" className="form-label labeFormlStyle">
                Email
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="email"
                type="test"
                placeholder=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="col-md">
              <label for="phone" className="form-label labeFormlStyle">
                Telefone
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control form-control-sm"
                id="phone"
                type="test"
                placeholder=""
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          <div className="clearfix mt-3">
            <button className="btn btn-sm float-end text-light sidItemL2">
              {load === true ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to="/listUser"
              type="button"
              className="btn btn-sm px-3 float-end me-1 borderbp ic2"
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
