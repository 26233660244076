import {toast } from "react-toastify";
import evaluatImg from "../Avaliac.svg";
import { useCallback, useEffect, useState } from "react";
import ModalAddSection from "../section/ModalAddSection";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import { sectionsCount, getInspector, solveMessage } from "../utils";
import ModalAddRoad from "./ModalAddRoad";
import EditSectionModal from "../section/EditSectionModal";
const tableHeadColor = { color: "#6080A3" };
const toastStyle = {fontSize: '13.3px'};

export const RoadsContract = ({
  contractId,
  originLink,
  contract,
  roadsContract,
  sectionsContract,
  reloadRoadsContract,
}) => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const [roads, setRoads] = useState([]);
  const [users, setUsers] = useState([]);

  const [roadIdClicked, setRoadIdClicked] = useState("");
  const [roadCodClicked, setRoadCodClicked] = useState("");
  const [roadExtClicked, setRoadExtClicked] = useState("");
  const [sectionIdClicked, setSectionIdClicked] = useState();
  const [sectionsRoadCount, setSectionsRoadCount] = useState();

  const [load, setLoad] = useState(false);
  const [openModalAddSection, setOpenModalAddSection] = useState(false);
  const [openEditSectionModal, setOpenEditSectionModal] = useState(false);
  const [openModalAddRoad, setOpenModalAddRoad] = useState(false);
  const navigate = useNavigate();

  const getInspectors = useCallback(async () => {
    try {
      const res = await axios.get(`${url}/keycloak/users?role=inspector`, {
        headers: { authorization: `Bearer ${user.access_token}` },
      });
      setUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token, setUsers]);

  const getRoads = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/road`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setRoads(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token, setRoads]);

  useEffect(() => {
    //getInspectors();
    //getRoadsContract();
     getRoads();
  }, [getRoads, getInspectors]);

  const openModal = async (roadId, roadCod, roadExtens) => {
    const countSectionExt = sectionsCount(sectionsContract, roadId);

    setRoadIdClicked(roadId);
    setRoadCodClicked(roadCod);
    setSectionsRoadCount(countSectionExt);
    setRoadExtClicked(roadExtens);
    setOpenModalAddSection(true);
  };

  const openEditModal = async (sectionId) => {
    setOpenEditSectionModal(true);
    setSectionIdClicked(sectionId);
  };

  const getSaved = async () => {
    setOpenModalAddSection(false);
    setOpenModalAddRoad(false);
    setOpenEditSectionModal(false)
    setOpenModalAddSection(false)
    reloadRoadsContract();
  };

  const deleteRoadContract = async (contractId, roadId) => {
    setLoad(true);
    try {
      await axios.get(
        `${url}/contract/deleteRoadContract/${contractId}/${roadId}`,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false);
      toast.success('Deletado', {style: toastStyle});
      reloadRoadsContract();
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  const deleteTroco = async (sectionId) => {
    try {
      await axios.put(`${url}/section/delete/${sectionId}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await reloadRoadsContract();
      toast.success("Deletado", {style: toastStyle});
    } catch (error) {
      console.log(error);
    }
  };

  const reporSection = async (contractId) => {
    try {
      await axios.get(`${url}/contract/reporSect/${contractId}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
    } catch (error) {
      console.log(solveMessage(error));
    }
  };

  return (
    <>
      <div
        className="shadow-lg bg-white"
        style={{
          paddingBottom: "35px",
          paddingLeft: "12px",
          paddingRight: "12px",
        }}
      >
        <div className="clearfix ">
          {contract && (
            <h5
              className="mt-4 float-start me-2"
              style={{ fontFamily: "Roboto" }}
            >
              Estradas
            </h5>
          )}
          <ModalAddRoad
            isOpen={openModalAddRoad}
            contractId={contractId}
            roads={roads}
            setModalAddRoadClose={() => setOpenModalAddRoad(false)}
            getSaved={getSaved}
          ></ModalAddRoad>

          {contract && originLink === "list" && (
            <button
              type="button"
              onClick={() => setOpenModalAddRoad(true)}
              className="btn btn-sm float-end ms-1 text-white mt-3 sidItemL2"
              disabled={contract.isClosed === true ? true : false}
            >
              <span>Adicionar Estrada</span>
              <i className="fas fa-plus-circle ms-2"></i>
            </button>
          )}

          {load === true && (
            <span className={"spinner-border float-end mt-3 me-3 ic"}></span>
          )}
        </div>

        {contract ? (
          roadsContract.map((road) => (
            road.status === true &&
            <div className="card shadow-lg mb-1">
              <div key={road.id} className="card-body">
                <div className="clearfix ">
                  <a
                    className="collapsed btn btn-sm float-start"
                    data-bs-toggle="collapse"
                    href={`#collapse${road.id}`}
                  >
                    <i className="fas fa-chevron-circle-down ic2 me-1"></i>
                    <span className="fw-bold me-2 mediunText">Código:</span>
                    <span className="smollText">{road.codigo}</span>
                    <span className="ms-5">
                      {sectionsCount(sectionsContract, road.id)}Km /{" "}
                      {road.extensao}Km
                      {" ( Total Troço / Total Estrada )"}
                    </span>
                    <span className="ms-5">{/*road.totalValue*/}</span>
                  </a>

                  {contract && originLink === "list" && (
                    <>
                      <button
                        type="button"
                        className="btn btn-sm float-end ms-2 text-white sidItemL2"
                        onClick={() =>
                          openModal(road.id, road.codigo, road.extensao)
                        }
                        disabled={contract.isClosed === true ? true : false}
                      >
                        <span>Troço</span>
                        <i className="fas fa-plus-circle ms-2"></i>
                      </button>
                      <button
                        type="button"
                        className={"btn btn-sm float-end borderbp"}
                        onClick={() => deleteRoadContract(contractId, road.id)}
                        disabled={contract.isClosed === true ? true : false}
                      >
                        <i className="fas fa-trash-alt ic2"></i>
                      </button>
                    </>
                  )}
                </div>

                <div
                  id={`collapse${road.id}`}
                  class="table-responsive collapse"
                  data-bs-parent="#accordion"
                >
                  <table class="table table-sm align-middle text-center smollText">
                    <thead style={tableHeadColor}>
                      <tr className="sidItemL3">
                        <th>kmInicial</th>
                        <th>KmFinal</th>
                        <th>Tipo de Pavimento</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="border border-top-0 border-end-0 border-start-0 ">
                      {sectionsContract?.length > 0 &&
                        sectionsContract.map(
                          (section) =>
                            section.road.id === road.id &&
                            section.status === true && (
                              <tr key={section.id}>
                                <td>{section.kmInicial}Km</td>
                                <td>{section.KmFinal}Km</td>
                                <td>
                                  {section.tipoHobra === "Calcada"
                                    ? "Calçada"
                                    : section.tipoHobra}
                                </td>

                                {contract && originLink === "list" ? (
                                  
                                  <td className="text-center ">
                                    <Link
                                      to={`/addPonderac/${section.id}`}
                                      class="fas fa-sitemap ic2 p-2"
                                      data-bs-toggle="tooltip"
                                      title="Serviços"
                                    ></Link>
                                    <button
                                      onClick={() =>
                                        deleteTroco(section.id)
                                      }
                                      className="btn fas fa-trash-alt p-2 ic2 smollText"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="right"
                                      title="Deletar"
                                      disabled={
                                        contract.isClosed === true
                                          ? true
                                          : false
                                      }
                                    ></button>
                                    <button
                                      className="btn fa fa-edit p-2 ic2 smollText"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="right"
                                      title="Editar"
                                      onClick={() =>
                                        openEditModal(section.id)
                                      }
                                      disabled={
                                        contract.isClosed === true
                                          ? true
                                          : false
                                      }
                                    ></button>
                                  </td>
                                ) : (
                                 <> 
                                 
                                <Link
                                  to={`/evaluation/${section.id}/${contract.initialDate}/${contract.finalDate}`}
                                  class="ic2 p-2"
                                  data-bs-toggle="tooltip"
                                  title="Avaliações"
                                >
                                  <img
                                    className="ic2"
                                    src={evaluatImg}
                                    style={{
                                      width: "14px",
                                      height: "15px",
                                    }}
                                    alt=""
                                  />
                                </Link>
                                <Link
                                  to={`/evaluation/${section.id}/${contract.initialDate}/${contract.finalDate}/map`}
                                  className="btn fas fa-map-marked-alt p-2 ic2 smollText"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="Mapa"
                                ></Link>
                                </>
                                )
                              }
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                </div>

                <ModalAddSection
                  isOpen={openModalAddSection}
                  roadId={roadIdClicked}
                  roadCod={roadCodClicked}
                  contractId={contractId}
                  contractName={contract.name}
                  sectionsRoadCount={sectionsRoadCount}
                  roadExtClicked={roadExtClicked}
                  setModalClose={getSaved}
                  getSaved={getSaved}
                ></ModalAddSection>

                <EditSectionModal
                  isOpen={openEditSectionModal}
                  sectionIdClicked={sectionIdClicked}
                  editSectionClose={ getSaved}
                ></EditSectionModal>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center pt-5">
            <i className="fas fa-road fs-3 ic2"></i>
            <p className="small text-muted mt-3">Sem Estrada Adicionadas</p>
            <button
              type="button"
              className="btn btn-sm text-light mt-3 sidItemL2"
              onClick={setOpenModalAddRoad}
              disabled={contract.isClosed === true ? true : false}
            >
              Adicionar
              <i className="fas fa-plus-circle ms-1"></i>
            </button>
          </div>
        )}
      </div>
    </>
  );
};
