import { useAuth } from "react-oidc-context";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { formatDateTime } from "../utils";
import List from "../List";

const ListRoad = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roads, setRoads] = useState([]);
  const { user } = useAuth();
  const columnTitles = [
    { key: "codigo", value: "Código" },
    { key: "name", value: "Nome" },
    { key: "classificcao", value: "Classificação" },
    { key: "extensao", value: "Extensão" },
    { key: "zone", value: "Zona" },
    { key: "lote", value: "Lote" },
    { key: "created_at", value: "Data Criação" },
  ];

  const getRoads = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/road`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      const data = response.data.map((v) => ({
        ...v,
        created_at: formatDateTime(v.created_at),
        lote: v.lote.name,
        zone: v.zone.name,
        extensao: `${v.extensao} Km`,
      }));
      setRoads(data);
      setFiltered(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, user.access_token]);

  useEffect(() => {
    getRoads();
  }, [getRoads]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = roads.filter(
      (road) =>
        road.codigo.match(pattern) ||
        road.name.match(pattern) ||
        road.classificcao.match(pattern) ||
        road.extensao.match(pattern) ||
        road.zone.match(pattern) ||
        road.lote.match(pattern)
    );
    setFiltered(results);
  };

  const deleteRoad = async (id) => {
    try {
      await axios.delete(`${url}/road/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getRoads();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List
      title="Estrada"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listRoad#"
      newLink="/addRoad"
      viewBaseLink="/viewRoad"
      editBaseLink="/editRoad"
      deleteItem={deleteRoad}
      loading={loading}
    />
  );
};

export default ListRoad;
