import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";

const ViewCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [responsable, setResponsable] = useState([]);
  const [company, setCompany] = useState([]);
  const { id } = useParams();
  const { user } = useAuth();
  const tableHeadColor = { color: "#6080A3" };
  const lisLine = { marginTop: "-1px" };
  const ulmargTop = { marginTop: "-15px" };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`${url}/company/${id}`, {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        });
        setCompany(response.data[0]);
        setResponsable(response.data[0].responsable);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [url, id, user.access_token, setCompany, setResponsable]);

  return (
    <div className="container-sm pt-4">
      <div className="mx-auto w-75">
        <p className="small">
          <Link to="/" className="mutec text-decoration-none ic">
            Home »{" "}
          </Link>
          <Link to="/listCompany" className="mutec text-decoration-none ic">
            Empresa »{" "}
          </Link>
          <span className="ic">Visualizar Empresa</span>
        </p>
        <h5
          className=" mb-3 floa-start ic"
          style={{ fontFamily: "Roboto" }}
        >
          Visualizar Empresa
        </h5>

        <div className="bg-white shadow-lg mb-1 pt-3 pb-1">
          <div class="row mx-auto w-75">
            <div className="col-sm-6">
              <h6 className="ic">Dados da Empresa</h6>
              <hr style={lisLine} />
              <ul className="list-group smollText" style={ulmargTop}>
                <li className="list-group-item border-0">
                  <span className="ic">Nome</span>
                  <br />
                  <span className="mutec">{company.name}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">NIF</span>
                  <br />
                  <span className="mutec">{company.nif}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Endereço</span>
                  <br />
                  <span className="mutec">{company.addres}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Email</span>
                  <br />
                  <span className="mutec">{company.email}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Telefone</span>
                  <br />
                  <span className="mutec">{company.telephone}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Data Criação</span>
                  <br />
                  <span className="mutec">{company.created_at}</span>
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <h6 className="ic">Dados do Responsável</h6><hr style={lisLine}/>
              <ul className="list-group smollText" style={ulmargTop}>
                <li className="list-group-item border-0">
                  <span className="ic">Nome</span>
                  <br />
                  <span className="mutec">{responsable.name}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Função</span>
                  <br />
                  <span className="mutec">{responsable.funct}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Email</span>
                  <br />
                  <span className="mutec">{responsable.email}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Telefone</span>
                  <br />
                  <span className="mutec">{responsable.telephone}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Residência</span>
                  <br />
                  <span className="mutec">{responsable.resicende}</span>
                </li>
                <li className="list-group-item border-0">
                  <span className="ic">Data Criação</span>
                  <br />
                  <span className="mutec">{responsable.created_at}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCompany;
