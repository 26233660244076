import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import List from "../List";

const ListCompany = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [companies, setCompanies] = useState([]);
  const { user } = useAuth();
  const columnTitles = [
    { key: "name", value: "Nome" },
    { key: "nif", value: "NIF" },
    { key: "address", value: "Endereço" },
    { key: "email", value: "Email" },
    { key: "telephone", value: "Telefone" },
    { key: "responsable", value: "responsável" },
  ];

  const getCompanies = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/company`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      const data = response.data.map((v) => ({
        ...v,
        responsable: v.responsable.name,
      }));
      setCompanies(data);
      setFiltered(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [url, user.access_token]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const filter = (e) => {
    const pattern = RegExp(e.target.value, "i");
    const results = companies.filter(
      (company) =>
        company.name.match(pattern) ||
        company.address.match(pattern) ||
        company.email.match(pattern) ||
        company.responsable.match(pattern)
    );
    setFiltered(results);
  };

  const deleteCompany = async (id) => {
    try {
      await axios.delete(`${url}/company/${id}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      await getCompanies();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <List
      title="Empresa"
      columnTitles={columnTitles}
      items={filtered}
      filter={filter}
      originLink="/listCompany#"
      newLink="/addCompany"
      viewBaseLink="/viewCompany"
      editBaseLink="/editCompany"
      deleteItem={deleteCompany}
      loading={loading}
    />
  );
};

export default ListCompany;
