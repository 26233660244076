import L from "leaflet";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useAuth } from "react-oidc-context";
import omnivore from "@mapbox/leaflet-omnivore";

export const KmlLayer = ({ setMapRoad }) => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const map = useMap();
  const [info, setInfo] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetch(`${url}/kml`, {
        headers: { authorization: `Bearer ${user.access_token}` },
      });
      const text = await response.text();
      const style = () => ({
        color: "#FBC581",
        weight: 2,
      });

      const layer = await omnivore.kml.parse(text);
      layer.setStyle(style);
      layer.eachLayer((l) => {
        l.on("mouseout", () => {
          layer.setStyle(style);
          info.update();
        });

        l.on("mouseover", (e) => {
          const layer = e.target;
          layer.setStyle({
            weight: 5,
            color: "#e6550d",
            fillOpacity: 0.7,
          });
          layer.bringToFront();
          info.update(layer.feature.properties);
        });

        l.on("click", (e) => {
          const prop = e.target.feature.properties;
          setMapRoad({
            code: prop.CV_CodEstr,
            classification: prop.CV_Classe,
            extremes: prop.CV_Limite,
          });
        });
      });
      layer.addTo(map);
      info.update = function (props) {
        console.log(props);
        this._div.innerHTML = `<h6>Informação de Estrada</h6>
          ${
            props
              ? `<b>ID:</b> ${props.CV_Id}<br/>
		 <b>Código:</b> ${props.CV_CodEstr}<br/>
		 <b>Tutela:</b> ${props.CV_TUTELA}<br/>
		 <b>Ilha:</b> ${props.CV_ILHA}<br/>
		 <b>Limite:</b> ${props.CV_Limite}<br/>
		 <b>Classe:</b> ${props.CV_Classe}<br/>`
              : "Coloque o rato numa estrada"
          }`;
      };
      info.onAdd = function () {
        this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
        this.update();
        return this._div;
      };

      info.addTo(map);
    })();
  }, [info, map, user.access_token, url, setMapRoad]);

  useEffect(() => {
    setInfo(L.control());
  }, [setInfo]);

  return null;
};
