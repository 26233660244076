import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";

const EditSection = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [feedMessage, setFeedMessage] = useState("default");
  const [contract_id, setContract_id] = useState("");
  const [tipo_hobra, setTipoHobra] = useState("");
  const [kmInicial, setKmInicial] = useState("");
  const [contracts, setContracts] = useState([]);
  const [KmFinal, setKmFinal] = useState("");
  const [road_id, setRoad_id] = useState("");
  const [user_id, setUser_id] = useState("");
  const [evaluationParameter, setEvaluationParameter] = useState("");
  const [roads, setRoads] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();

  const getRoads = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/road/${user.profile.sub}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setRoads(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.profile.sub, user.access_token, setRoads]);

  const getContracts = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/contract`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setContracts(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token, setContracts]);

  const getUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/user/${user.profile.sub}`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.profile.sub, user.access_token, setUsers]);

  const getSectionById = useCallback(async () => {
    try {
      const response = await axios.get(
        `${url}/section/${id}/${user.profile.sub}`,
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setKmInicial(response.data[0].kmInicial);
      setKmFinal(response.data[0].KmFinal);
      setTipoHobra(response.data[0].tipoHobra);
      setRoad_id(response.data[0].road.id);
      setContract_id(response.data[0].contract.id);
      setUser_id(response.data[0].user.id);
    } catch (error) {
      console.log(error);
    }
  }, [
    url,
    id,
    user.profile.sub,
    user.access_token,
    setKmInicial,
    setKmFinal,
    setTipoHobra,
    setRoad_id,
    setContract_id,
    setUser_id,
  ]);

  useEffect(() => {
    getRoads();
    getContracts();
    getUsers();
    getSectionById();
  }, [getRoads, getContracts, getUsers, getSectionById]);

  const updateSection = async (e) => {
    e.preventDefault();
    setFeedMessage("");
    try {
      const response = await axios.put(
        `${url}/section/${id}/${user.profile.sub}`,
        {
          kmInicial: kmInicial,
          KmFinal: KmFinal,
          tipoHobra: tipo_hobra,
          road: road_id,
          contract: contract_id,
          userId: user_id,
          evaluationParameter: evaluationParameter
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setFeedMessage(response.statusText);
      navigate(`/viewContract/${contract_id}`);
    } catch (error) {
      console.log(error);
      setFeedMessage(error.response.statusText);
    }
  };

  if (KmFinal < 0) {setKmFinal(0)}
  if (evaluationParameter < 0) {setEvaluationParameter(0)}

  return (
    <div className="container-sm pt-4 text-black ">
      <h4 className="text-left mb-1 pt-4 ms-2" style={{ fontFamily: "Roboto" }}>
        Editar Troço de Estrada
      </h4>

      <div className="detail shadow-lg ms-2 me-2">
        <form className=" p-3 mx-auto w-75" onSubmit={updateSection}>
          <div className="row">
            <div className="col">
              <label for="road_id" className="form-label">
                Codigo de Estrada
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="road_id"
                className="form-control"
                value={road_id}
                onChange={(e) => setRoad_id(e.target.value)}
              >
                <option value=""></option>
                {roads.map((road) => (
                  <option value={road.id}>{road.codigo}</option>
                ))}
              </select>
            </div>

            <div className="col">
              <label for="contract_id" className="form-label">
                Contrato
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="contract_id"
                className="form-control"
                value={contract_id}
                onChange={(e) => setContract_id(e.target.value)}
              >
                <option value=""></option>
                {contracts.map((contract) =>
                  contract.isClosed === true ? (
                    ""
                  ) : (
                    <option value={contract.id}>{contract.name}</option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label for="kmInicial" className="form-label">
                Km Inicial
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="kmInicial"
                type="number"
                placeholder=""
                value={kmInicial}
                onChange={(e) => setKmInicial(e.target.value)}
              />
            </div>
            <div className="col">
              <label for="KmFinal" className="form-label">
                Km Final
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="KmFinal"
                type="number"
                placeholder=""
                value={KmFinal}
                onChange={(e) => setKmFinal(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label for="tipo_hobra" className="form-label">
                Tipo de Pavimento
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="tipo_hobra"
                className="form-control"
                value={tipo_hobra}
                onChange={(e) => setTipoHobra(e.target.value)}
              >
                <option value=""></option>
                <option value="Calcada">Calçada</option>
                <option value="Betuminoso">Betuminoso</option>
              </select>
            </div>

            <div className="col">
              <label for="user_id" className="form-label">
                Inspetor
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="user_id"
                className="form-control"
                value={user_id}
                onChange={(e) => setUser_id(e.target.value)}
              >
                <option value=""></option>
                {users.map((user) =>
                  user.typeuser.name !== "Inspetor" ? (
                    ""
                  ) : (
                    <option value={user.id}>{user.name}</option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
            <label for="pe" className="form-label">
                Parametro de Avaliação
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="pe"
                type="number"
                placeholder=""
                value={evaluationParameter}
                onChange={(e) => setEvaluationParameter(e.target.value)}
              />
            </div>

            <div className="col">
            </div>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light"
              style={{ backgroundColor: "#EF7800" }}
            >
              {feedMessage === "" ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>
            <Link
              to="/listSection"
              type="button"
              className="btn btn-sm px-3 float-end me-2"
              style={{ borderColor: "#EF7800", color: "#EF7800" }}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSection;
