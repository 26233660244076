import React from "react";
import { toast } from "react-toastify";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import { solveMessage } from "../utils";

const BACKGROUND_STYLE = {
  position: "fixed",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  backgroundColor: "rgb(0,0,0, 0.7)",
  //sobre põe uma tela sobre outra
  zIndex: "1000",
  overflowY: "auto",
};

const MODAL_STYLE = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  padding: "20px",
  backgroundColor: "#fff",
  //borderRadius: '10px',
  color: "black",
  maxHeight: "420px",
  overflowX: "hiden",
  overflowY: "auto",
};

const toastStyle = {fontSize: '13.3px'};

export default function ModalAddSection({
  isOpen,
  roadId,
  roadCod,
  contractId,
  contractName,
  sectionsRoadCount,
  roadExtClicked,
  setModalClose,
  getSaved,
}) {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const { user } = useAuth();
  const [KmFinal, setKmFinal] = useState(0);
  const [tipo_hobra, setTipoHobra] = useState("");
  const [evaluationParameter, setEvaluationParameter] = useState("");
  const [load, setLoad] = useState(false);

  const saveSection = async (e) => {
    e.preventDefault();
    setLoad(true);
    if (KmFinal <= sectionsRoadCount) {
      toast.error("KmFinal tem que ser Superior a KmInicial", {style: toastStyle});
      setLoad(false);
      return;
    }

    try {
      await axios.post(
        `${url}/section`,
        {
          kmInicial: sectionsRoadCount,
          KmFinal: KmFinal,
          tipoHobra: tipo_hobra,
          road: roadId,
          contract: contractId,
          evaluationParameter: evaluationParameter,
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );
      setLoad(false);
      toast.success("Adicionado com Sucesso", {style: toastStyle});
      getSaved();
    } catch (error) {
      setLoad(false);
      
      toast.error(solveMessage(error), {style: toastStyle});
    }
  };

  if (KmFinal < 0) {
    setKmFinal(0);
  }
  if (evaluationParameter < 0) {
    setEvaluationParameter(0);
  }

  if (isOpen) {
    return (
      <div className="" style={BACKGROUND_STYLE}>
        <div className="smollText" id="modalContainer" style={MODAL_STYLE}>
          <div className="form">
            <form onSubmit={saveSection}>
              <div className="row">
                <div className="col-md">
                  <label for="road_id" className="form-label">
                    Codigo de Estrada
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                      *
                    </span>
                  </label>
                  <select
                    id="road_id"
                    className="form-select form-select-sm"
                    //value={road_id}
                    //onChange={(e) => setRoad_id(e.target.value)}
                  >
                    <option value={roadId}>{roadCod}</option>
                  </select>
                </div>

                <div className="col-md">
                  <label for="contract_id" className="form-label">
                    Contrato
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                      *
                    </span>
                  </label>
                  <select
                    id="contract_id"
                    className="form-select form-select-sm"
                    // value={contract_id}
                    //onChange={(e) => setContract_id(e.target.value)}
                  >
                    <option value={contractId}>{contractName}</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <label for="kmInicial" className="form-label">
                    Km Inicial
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="kmInicial"
                    type="number"
                    placeholder=""
                    value={sectionsRoadCount}
                    //onChange={(e) => setKmInicial(e.target.value)}
                    readonly="true"
                  />
                </div>

                <div className="col-md">
                  <label for="KmFinal" className="form-label">
                    Km Final
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="KmFinal"
                    type="number"
                    placeholder=""
                    value={KmFinal}
                    onChange={(e) => setKmFinal(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <label for="tipo_hobra" className="form-label">
                    Tipo de Pavimento
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                      *
                    </span>
                  </label>
                  <select
                    id="tipo_hobra"
                    className="form-select form-select-sm"
                    value={tipo_hobra}
                    onChange={(e) => setTipoHobra(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="Calcada">Calçada</option>
                    <option value="Betuminoso">Betuminoso</option>
                  </select>
                </div>

                <div className="col-md">
                  <label for="KmFinal" className="form-label">
                    Parametro de Avaliação
                    <span className="text-danger" style={{ fontSize: "22px" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    id="KmFinal"
                    type="number"
                    placeholder=""
                    value={evaluationParameter}
                    onChange={(e) => setEvaluationParameter(e.target.value)}
                  />
                </div>
              </div>

              <div className="clearfix mt-3">
                <button className="btn btn-sm float-end text-light sidItemL2">
                  {load === true ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Salvar"
                  )}
                </button>

                <button
                  preventScrollReset={false}
                  type="button"
                  className="btn btn-sm px-3 float-end me-2 borderbp ic2"
                  onClick={setModalClose}
                >
                  Cancelar
                </button>

                <span className="float-start ms-5 text-muted">
                  {sectionsRoadCount} / {roadExtClicked}Km
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
