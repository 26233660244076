import { SideBar } from "../components/sidebar/sidebar";
import { Navbar } from "../components/navbar/navbar";
import { Outlet } from "react-router-dom";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useEffect } from "react";
import AwaitApproval from "./AwaitApproval";

const Layout = () => {
  const { isAuthenticated, activeNavigator, isLoading, signinRedirect, user } =
    useAuth();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading
    ) {
      signinRedirect();
    }
  }, [isAuthenticated, activeNavigator, isLoading, signinRedirect]);

  if (isAuthenticated && !user.profile.roles) {
    return (
      <div className="position-relative">
        {<Navbar />}
        <main style={{ marginTop: "58px" }}>
          <AwaitApproval />
        </main>
      </div>
    );
  } else if (isAuthenticated) {
    return (
      <div className="position-relative">
        <SideBar />
        {<Navbar />}
        <main style={{ marginTop: "58px" }}>
          <Outlet />
        </main>
      </div>
    );
  } else {
    return <span>Loading...</span>;
  }
};

export default Layout;
