import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import axios from "axios";
import { solveMessage } from "../utils";

const AddSection = () => {
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  const [feedMessage, setFeedMessage] = useState("default");
  const [contract_id, setContract_id] = useState("");
  const [tipo_hobra, setTipoHobra] = useState("");
  const [contracts, setContracts] = useState([]);
  //const [kmInicial, setKmInicial] = useState();
  const [road_id, setRoad_id] = useState("");
  const [user_id, setUser_id] = useState("");
  const [evaluationParameter, setEvaluationParameter] = useState("");
  const [KmFinal, setKmFinal] = useState(0);
  const [roads, setRoads] = useState([]);
  const [users, setUsers] = useState([]);
  const { contractId } = useParams();
  const { roadExt } = useParams();
  const { roadId } = useParams();
  const { totalsectionExt } = useParams();
  const navigate = useNavigate();
  var contractName = "default";
  const { user } = useAuth();

  const getInspectors = useCallback(async () => {
    try {
      const res = await axios.get(`${url}/keycloak/users?role=inspector`, {
        headers: { authorization: `Bearer ${user.access_token}` },
      });
      setUsers(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token]);

  const getRoads = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/road`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setRoads(response.data);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [url, user.access_token, setRoads]);

  const getContracts = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/contract`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setContracts(response.data);
    } catch (error) {
      console.log(solveMessage(error));
    }
  }, [url, user.access_token, setContracts]);

  const getUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${url}/user`, {
        headers: {
          Authorization: "Bearer " + user.access_token,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [url, user.access_token, setUsers]);

  useEffect(() => {
    getRoads();
    getContracts();
    getUsers();
    setRoad_id(roadId);
    setContract_id(contractId);
    getInspectors();
  }, [
    getRoads,
    getContracts,
    getUsers,
    setRoad_id,
    roadId,
    setContract_id,
    contractId,
    getInspectors,
  ]);

  const saveSection = async (e) => {
    e.preventDefault();
    if (KmFinal <= totalsectionExt) {
      console.log("KmFinal tem que ser Superior a KmInicial");
      return;
    }
    setFeedMessage("");
    try {
      const response = await axios.post(
        `${url}/section`,
        {
          kmInicial: totalsectionExt,
          KmFinal: KmFinal,
          tipoHobra: tipo_hobra,
          road: road_id,
          contract: contract_id,
          userId: user_id,
          evaluationParameter: evaluationParameter
        },
        {
          headers: {
            Authorization: "Bearer " + user.access_token,
          },
        }
      );

      setFeedMessage(response.statusText);
      navigate(`/viewRoad/${roadId}/${contractId}/${contractName}`);
    } catch (error) {
      setFeedMessage(error.response.statusText);
      console.log(solveMessage(error));
    }
  };

  if (KmFinal < 0) {setKmFinal(0)}
  if (evaluationParameter < 0) {setKmFinal(0)}

  return (
    <div className="container-sm pt-4 text-black ">
      <div className="clearfix">
        <h4 className="mt-3 ms-2 float-start" style={{ fontFamily: "Roboto" }}>
          Criar Troço de Estrada
        </h4>
        <h5 className="mt-4 me-2 float-end">
          {+contractId === 0 ? "" : totalsectionExt + "/" + roadExt + "Km"}
        </h5>
      </div>

      <div className="detail shadow-lg ms-2 me-2">
        <form className=" p-3 mx-auto w-75" onSubmit={saveSection}>
          <div className="row">
            <div className="col">
              <label for="road_id" className="form-label">
                Codigo de Estrada
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="road_id"
                className="form-control"
                value={road_id}
                onChange={(e) => setRoad_id(e.target.value)}
              >
                {roads.map((road) =>
                  road.id === +roadId ? (
                    <option value={road.id}>{road.codigo}</option>
                  ) : (
                    ""
                  )
                )}
              </select>
            </div>

            <div className="col">
              <label for="contract_id" className="form-label">
                Contrato
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="contract_id"
                className="form-control"
                value={contract_id}
                onChange={(e) => setContract_id(e.target.value)}
              >
                {contracts.map((contract) =>
                  contract.id === +contractId ? (
                    <option value={contract.id}>
                      {(contractName = contract.name)}
                    </option>
                  ) : (
                    ""
                  )
                )}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label for="kmInicial" className="form-label">
                Km Inicial
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="kmInicial"
                type="number"
                placeholder=""
                value={totalsectionExt}
                //onChange={(e) => setKmInicial(e.target.value)}
                readonly="true"
              />
            </div>

            <div className="col">
              <label for="KmFinal" className="form-label">
                Km Final
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="KmFinal"
                type="number"
                placeholder=""
                value={KmFinal}
                onChange={(e) => setKmFinal(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label for="tipo_hobra" className="form-label">
                Tipo de Pavimento
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="tipo_hobra"
                className="form-control"
                value={tipo_hobra}
                onChange={(e) => setTipoHobra(e.target.value)}
              >
                <option value=""></option>
                <option value="Calcada">Calçada</option>
                <option value="Betuminoso">Betuminoso</option>
              </select>
            </div>

            <div className="col">
              <label for="user_id" className="form-label">
                Inspetor
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <select
                id="user_id"
                className="form-control"
                value={user_id}
                onChange={(e) => setUser_id(e.target.value)}
              >
                <option value=""></option>
                {users.map((inspector) => (
                  <option value={inspector.id}>
                    {inspector.firstName} {inspector.lastName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col">
            <label for="KmFinal" className="form-label">
                Parametro de Avaliação
                <span className="text-danger" style={{ fontSize: "22px" }}>
                  *
                </span>
              </label>
              <input
                className="form-control"
                id="KmFinal"
                type="number"
                placeholder=""
                value={evaluationParameter}
                onChange={(e) => setEvaluationParameter(e.target.value)}
              />
            </div>

            <div className="col">
            </div>
          </div>

          <div className="clearfix mt-3">
            <button
              className="btn btn-sm float-end text-light"
              style={{ backgroundColor: "#EF7800" }}
            >
              {feedMessage === "" ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "Salvar"
              )}
            </button>

            <Link
              to={`/viewRoad/${roadId}/${contractId}/${contractName}`}
              preventScrollReset={false}
              type="button"
              className="btn btn-sm px-3 float-end me-2"
              style={{ borderColor: "#EF7800", color: "#EF7800" }}
            >
              Cancelar
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSection;
